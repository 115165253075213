<template>

  <div>

  <b-col cols="12">
    <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :items="dataValue"
        :fields="fields"
        class="mb-0"
        show-empty
    >
      <template #cell(state)="data">
          <feather-icon
              v-b-tooltip.hover.v-primary="data.item.nameActionEstatus"
              :icon="data.item.iconActionEstatus"
              @click="desactivarActivarRetencion(data.item)"
              v-bind:class="data.item.colorActionEstatus"
          />

      </template>
      <template #cell(action)="row">
        <!-- Column: Action -->
        <div class="text-nowrap">
          <feather-icon
              v-b-tooltip.hover.v-primary="'Editar Retención'"
              icon="Edit2Icon"
              @click="editarRetencion(row.item)"
              v-b-modal.modal-editar
          />
        </div>
      </template>
      <template #empty="scope">
        <div class="text-center">No existen resultados</div>
      </template>
    </b-table>
  </b-col>
    <!-- Modal de Editar -->
    <b-modal
        id="modal-editar"
        title="Editar Retención"
        ok-title="Guardar"
        cancel-variant="outline-primary"
        size="lg"
        @ok="validationFormEditar"
    >
      <validation-observer ref="simpleRules">
        <b-form name="formCrear">
          <b-row>
            <b-col cols="6">
              <b-form-group
                  label="Periodo Retención (Año)"
                  label-for="h-periodo-retencion"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Periodo Retención"
                    rules="required|integer"
                >
                  <b-form-input
                      id="h-periodo-retencion"
                      v-model="periodoEditar"
                      placeholder="Periodo Retención (Año)"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                  label="Porcentaje Retención"
                  label-for="h-porcentaje-retencion"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Porcentaje Retención"
                    rules="required"
                >
                  <b-form-input
                      id="h-porcentaje-retencion"
                      v-model="porcentajeRetencionEditar"
                      placeholder="Porcentaje Retención(%)"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- Fin Modal de Editar -->
  </div>
</template>

<script>
import {
  BTable, BRow, BCol, BFormGroup, BTooltip, BFormSelect, BPagination, BAvatar, VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'


export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BTooltip,
    BFormSelect,
    BPagination,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: ['dataValue'],
  data() {
    return {
      perPage: 50,
      pageOptions: [50, 100, 500, 1000],
      page: 1,
      fields:
          [
            { key: 'periodo', label: 'Periodo' },
            { key: 'porcentaje_retencion', label: 'Retención' },
            { key: 'state', label: 'Vigencia' },
            { key: 'action', label: 'Acción' },
          ],
    }
  },

  mounted() {

  },
  computed:{
    currentPage() {
      return this.$store.state.retencionSii.currentPage
    },
  },
  methods: {
    editarRetencion(item) {
      console.log(item.porcentaje_retencion)
      //this.$store.commit('retencionSii/getRetencionToEdit', item)
      this.idEditar = item.id
      this.periodoEditar = item.periodo
      this.porcentajeRetencionEditar = item.porcentaje_retencion
    },
    editarRetencionConfirmado() {
      this.datosEditar = {
        id_retencion: this.idEditar,
        periodo: this.periodoEditar,
        porcentaje_retencion: this.porcentajeRetencionEditar,
        state: 'inactive',
      }
      this.$store.dispatch('retencionSii/editarRetencion', this.datosEditar)
    },
    validationFormEditar() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.editarRetencionConfirmado()
          this.$bvModal.hide('modal-editar')
        }
      })
    },
    desactivarActivarRetencion(data) {
      const datosEditar = {
        id_retencion: data.id,
      }
      const accion = data.state == 'inactive' ? 'Activar' : 'Inactivar'
      this.$bvModal
              .msgBoxConfirm(`¿Usted está seguro que desea ${accion} la retención?`, {
                title: 'Por favor Confirmar',
                size: 'm',
                okVariant: 'primary',
                okTitle: 'Si',
                cancelTitle: 'No',
                cancelVariant: 'outline-primary',
                hideHeaderClose: false,
                centered: true,
              })
              .then(value => {
                if (value) {
                  this.$store.dispatch('retencion-sii/activarDesactivarRetencion', datosEditar)
                }
              })

    },
  },
}
</script>
<style>
.icon-success {
  color: #5CB85C;
}
.icon-danger {
  color: #ea5455;
}

</style>
