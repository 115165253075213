<template>
  <div>
    <b-overlay :show="loading">
      <b-card
          class="text"
          title="Listado Retenciones"
      >
        <b-col>
          {{notificaciones}}
          <b-row>
            <b-col sm="12" md="6"  class="my-1 text-right">
              <div class="d-flex align-items-center mb-1 mb-md-0">
                <label>Mostrar</label>
                <b-form-select
                    id="perPageSelect1"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                    class="mx-50 col-md-2"
                    @change="onChangePerPage"
                ></b-form-select>
                <label>filas por página</label>
              </div>
            </b-col>
            <b-col md="6">
              <div class="d-flex justify-content-end text-right">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.modal-crear
                    variant="outline-primary"
                    @click="limpiarFormCrear()"
                >
                  <feather-icon
                      icon="FilePlusIcon"
                      class="mr-50"
                  />
                  Crear Retención
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <!-- Modal de Crear -->
        <b-modal
                id="modal-crear"
                title="Nueva Retención"
                ok-title="Guardar"
                cancel-variant="outline-primary"
                size="lg"
                @ok="validationFormCrear"
        >
          <validation-observer ref="simpleRules">
            <b-form name="formCrear">
              <b-row>
                <b-col cols="6">
                  <b-form-group
                          label="Periodo Retención (Año)"
                          label-for="h-periodo-retencion"
                  >
                    <validation-provider
                            #default="{ errors }"
                            name="Periodo Retención"
                            rules="required|integer"
                    >
                      <b-form-input
                              id="h-periodo-retencion"
                              v-model="periodoRetencion"
                              placeholder="Periodo Retención (Año)"
                              :state="errors.length > 0 ? false:null"
                              type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                          label="Porcentaje Retención"
                          label-for="h-porcentaje-retencion"
                  >
                    <validation-provider
                            #default="{ errors }"
                            name="Porcentaje Retención"
                            rules="required"
                    >
                      <b-form-input
                              id="h-porcentaje-retencion"
                              v-model="porcentajeRetencion"
                              placeholder="Porcentaje Retención"
                              :state="errors.length > 0 ? false:null"
                              type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
        <!-- Fin Modal de Crear -->
        <div>
          <tabla-retencion-sii
              :data-value="items"
              @getRetencionesList = "getRetencionesList"/>
        </div>
        <b-col>
          <b-row>
            <b-col  cols="8" class="mt-3">
              <div v-if="totalRows > 0">
                <span class="text-muted">  Mostrando del {{from}} al {{to}} de {{totalRows}} filas</span>
              </div>
            </b-col>
            <b-col
                cols="4" class="mt-3"
            >
              <b-pagination
                  :total-rows="totalRows"
                  :per-page="perPage"
                  :value="page"
                  align="right"
                  size="sm"
                  class="my-0"
                  @change="onPageChange"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-card>

    </b-overlay>
  </div>
</template>

<script>
import TablaRetencionSii from '@/views/retencionSii/components/TablaRetencionSii.vue'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
export default {
  components: {
    'tabla-retencion-sii': TablaRetencionSii,
    AppCollapse,
    AppCollapseItem
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      filters: {
        item_per_page: 10,
        page: 1,
      },
      idEditar: null,
      periodoEditar: null,
      porcentajeRetencionEditar: null,
      listaRetencion: [],
      periodoRetencion: null,
      porcentajeRetencion: null,
      perPage: 10,
      pageOptions: [10, 20, 50, 100],
      sortBy: '',
      page: 1,
      notificacion: {},
    }
  },
  mounted() {
    this.getRetencionesList();
  },
  computed: {
    items() {
      this.listaRetencion = this.$store.state.retencionSii.retenciones
      console.log('this.listaRetencion',this.listaRetencion)
      if (this.listaRetencion !== undefined) {
        return this.listaRetencion.map(item => ({
          id: item.id,
          periodo: item.periodo,
          porcentaje_retencion: item.porcentaje_retencion,
          state: item.state,
          iconActionEstatus: item.state == 'active' ? 'CheckIcon' : 'XIcon',
          nameActionEstatus: item.state == 'active' ? 'Cambiar vigencia de Retención a Inactiva' : 'Cambiar vigencia de Retención a Activa',
          colorActionEstatus: item.state == 'active' ? 'icon-success' : 'icon-danger',
        }))
      }
    },
    totalRows() {
      return this.$store.state.retencionSii.rowsTotal;
    },
    to() {
      return this.$store.state.retencionSii.to;
    },
    from() {
      return this.$store.state.retencionSii.from;
    },
    currentPage() {
      return this.$store.state.retencionSii.currentPage
    },
    loading() {
      return this.$store.state.retencionSii.loading;
    },
    notificaciones() {
      this.notificacion = this.$store.state.retencionSii.notificacion
      if (this.notificacion.mensaje !== '') {
        this.$bvToast.toast(this.notificacion.mensaje, {
          title: `ALERTA!!`,
          variant: this.notificacion.variant,
          solid: true
        });
        this.getRetencionesList()
      }
    },
  },
  methods: {
    getRetencionesList() {
      this.$store.dispatch('retencion-sii/getRetencionesList', this.filters)
    },
    limpiarFormCrear() {
      this.periodo = null
      this.porcentaje_retencion = null
      // You should call it on the next frame
      requestAnimationFrame(() => {
        this.$refs.simpleRules.reset()
      })
    },
    validationFormCrear(objeto) {
      objeto.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.agregarRetencion()
          this.$bvModal.hide('modal-crear')
        }
      })
    },
    agregarRetencion() {
      this.dataCrear = {
        periodo: this.periodoRetencion,
        porcentaje_retencion: this.porcentajeRetencion,
      }
      this.$store.dispatch('retencion-sii/insertarRetencion', this.dataCrear)
      this.limpiarFormCrear()
    },

    onPageChange(page) {
      this.filters.page = page;
      this.getRetencionesList();
    },
    onChangePerPage() {
      this.filters.page = 1;
      this.filters.item_per_page = this.perPage;
      this.getRetencionesList();
    },
  },
}
</script>
